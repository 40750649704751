import { defineStore } from 'pinia';
import { Login } from '@/interfaces/Login';
import { AuthUser, RegisterData } from '@/interfaces/AuthUser';
import { useUserStore } from '../me';
import { Preferences } from '@capacitor/preferences';
import { hashSync } from 'bcryptjs';
import { ChangePassword, SetExistingAccountPassword } from '@/interfaces/ChangePassword';
import { toastController } from '@ionic/vue';
import { useResetStores } from '@/utils/resetStores';
import { useIndexStore } from '@/stores/index';
import { impact } from '@/composables/haptics';

interface LoginData {
  email: string;
  password: string;
}

export const useLoginStore = defineStore('Login', {
  state: (): Login => {
    return {
      status: '',
      token: '',
      user: {} as AuthUser,
      registerForm: {} as RegisterData,
      meta: {
        isPartnerRealtor: false,
        isUserRegistered: false,
        userHasPassword: false,
        userIsRealtor: false,
        isTermsAccepted: false,
        partnerAgencies: [],
        agencyNetworks: [],
      },
    };
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
  actions: {
    AUTH_REQUEST() {
      this.status = 'loading';
    },
    AUTH_SUCCESS(token: string, user: AuthUser) {
      this.status = 'success';
      this.token = token;
      this.user = user;
    },
    AUTH_ERROR() {
      this.status = 'error';
    },
    async AUTH_LOGOUT() {
      await Preferences.remove({
        key: 'token',
      });
      await Preferences.remove({
        key: 'user',
      });
      await Preferences.remove({
        key: 'registrationEmail',
      });
    },
    async SET_STORAGE_DATA(token: string, user: AuthUser) {
      await Preferences.set({
        key: 'token',
        value: token,
      });
      await Preferences.set({
        key: 'user',
        value: JSON.stringify(user),
      });
    },
    async SET_EMAIL_IN_LOCAL_STORAGE(email: string) {
      await Preferences.set({
        key: 'registrationEmail',
        value: email,
      });
    },
    async GET_EMAIL_FROM_LOCAL_STORAGE() {
      const email = await Preferences.get({ key: 'registrationEmail' });
      return email.value;
    },
    async initState() {
      const userStore = useUserStore();

      const token = await Preferences.get({ key: 'token' });
      const user = await Preferences.get({ key: 'user' });

      const urlParams = new URLSearchParams(window.location.search);
      const tokenParam = urlParams.get('token');

      if (!token.value || !user.value) {
        if (!tokenParam) return this.logout();
      } else {
        this.token = token.value;
        this.user = JSON.parse(user.value as string) || {};

        await userStore.fetchUserData().catch(() => {
          return this.logout();
        });
      }
    },
    logout() {
      return new Promise((resolve) => {
        this.AUTH_LOGOUT();
        this.$router.push({ name: 'SignIn' });
        useResetStores();
        resolve(true);
      });
    },
    updateUser(data: AuthUser) {
      return new Promise((resolve, reject) => {
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };
        this.$axios
          .put('/api/me', data, { headers: headers })
          .then(async (response) => {
            const user = { ...this.user, ...data };
            this.user = user;
            await Preferences.set({
              key: 'user',
              value: JSON.stringify(user),
            });
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changePassword(data: ChangePassword) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/set-password', data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login(user: LoginData) {
      return new Promise((resolve, reject) => {
        this.AUTH_REQUEST();
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Auth-Method': 'email',
        };
        this.$axios
          .post(
            'api/login',
            { email: user.email, password: user.password, accepted_terms_and_conditions: true },
            { headers: headers }
          )
          .then(async (response) => {
            const token = response.data.token;
            const user = response.data.user;

            this.SET_STORAGE_DATA(token, user);
            await this.checkUser();
            await impact();
            this.AUTH_SUCCESS(token, user);
            const indexStore = useIndexStore();
            indexStore.redirect_after_login
              ? this.$router.push({ path: indexStore.redirect_after_login })
              : this.$router.push({ name: 'Home' });
            indexStore.redirect_after_login = null;
            resolve(response);
          })
          .catch((err) => {
            this.AUTH_ERROR();
            reject(err);
          });
      });
    },
    async register() {
      try {
        this.AUTH_REQUEST();
        const headers = {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };
        const response = await this.$axios.post('/api/roots/register', this.registerForm, { headers: headers });

        if (response.status === 200) {
          this.$router.push({ name: 'SignIn' });
          await this.login({ email: this.registerForm.email, password: this.registerForm.password || '' });

          const toast = await toastController.create({
            message: 'Votre compte a été créé avec succès. Bienvenue sur BeanstockPro!',
            duration: 4000,
            position: 'top',
            mode: 'ios',
            buttons: [
              {
                text: 'Fermer',
                role: 'cancel',
              },
            ],
          });
          await toast.present();
        } else if (response.status === 202) {
          // screen that shows account creation request has been sent in case where user not partner
          this.$router.push({ name: 'AccountRequestScreen' });
        }

        return { status: response.status };
      } catch (err) {
        this.AUTH_ERROR();
        throw err;
      }
    },
    resetPassword(email: string) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/forgot-password', { email })
          .then((response) => {
            this.SET_EMAIL_IN_LOCAL_STORAGE(email);
            resolve(response.data.message);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
    setExistingAccountPassword(data: SetExistingAccountPassword) {
      const hashedToken = hashSync(data.token);

      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/reset-password', { ...data, token: hashedToken })
          .then(async (response) => {
            const email = (await this.GET_EMAIL_FROM_LOCAL_STORAGE()) || '';
            await this.login({ email, password: data.password });
            resolve(response);
          })
          .catch((err) => {
            this.$router.push({ name: 'SignIn' });
            reject(err);
          });
      });
    },
    checkUser() {
      const presentToast = async () => {
        const toast = await toastController.create({
          message:
            'Nous sommes navrés, vous n’avez pas encore accès à BeanstockPro - si vous êtes agent, nous vous prions de contacter votre Responsable Partenariats ou votre Account Manager',
          duration: 6000,
          cssClass: 'button-bottom',
          position: 'top',
          mode: 'ios',
          buttons: [
            {
              text: 'Fermer',
              role: 'cancel',
            },
          ],
        });
        await toast.present();
      };

      return new Promise<void>((resolve) => {
        const userStore = useUserStore();
        userStore
          .fetchUserData()
          .then(() => {
            resolve();
          })
          .catch(() => {
            presentToast();
            this.logout();
          });
      });
    },
    fetchAgencyNetworks() {
      return new Promise<void>((resolve, reject) => {
        this.$axios
          .get('/api/roots/networks')
          .then((response) => {
            this.meta.agencyNetworks = response.data.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setIsPartnerRealtor(value: boolean) {
      this.meta.isPartnerRealtor = value;
    },
    async sendEmailForVerificationCode(email: string) {
      return this.$axios
        .post('/api/email-verifications/send', { email })
        .then((response) => {
          const data = response.data;
          this.meta.isUserRegistered = data.user_exists;
          this.meta.userHasPassword = data.user_has_password;
          this.meta.userIsRealtor = data.user_is_realtor;
          this.meta.isTermsAccepted = data.realtor_accepted_terms_and_conditions;
          this.meta.isPartnerRealtor = Object.keys(data.agencies).length > 0;
          this.meta.partnerAgencies = data.agencies;
          return data.message;
        })
        .catch((err) => {
          throw err.response;
        });
    },
    verifyEmailCode(code: string, email: string) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post('/api/email-verifications/verify', { code, email })
          .then(async (response) => {
            await impact();
            resolve(response.data.message);
          })
          .catch((err) => {
            reject(err.response);
          });
      });
    },
  },
});
